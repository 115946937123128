import React, { Fragment } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import TextContentWithLogo from "../components/blocks/TextContentWithLogo";
import ContentRightWithToggle from "../components/blocks/ContentRightWithToggle";
import CtaBoxes from "../components/blocks/CTABoxes";

import Seo from 'gatsby-plugin-wpgraphql-seo';

export default function State({ data, location }) {
  const state = data?.wpState
  const blocks = state?.blocks?.layouts
  const wpSettings = data?.wp?.themeGeneralSettings?.themeSettings
  console.log('state', data);

  return (
      <Fragment>
        <Seo post={state} />
        <Layout location={location}>
            {blocks && blocks.length && blocks.map(block => {
                if (block && block.fieldGroupName) {
                    const name = block?.fieldGroupName.replace('State_Blocks_Layouts_', '') // get block name
                    console.log(name)

                    if (name === 'TextContentWithLogo') {
                        return (
                            <TextContentWithLogo data={block} slug={state?.slug} location={location} key={`page-block-${name}`} />
                        )
                    }

                    if (name === 'ContentRightWithToggle') {
                        return (
                            <ContentRightWithToggle data={block} slug={state?.slug} location={location} key={`page-block-${name}`} />
                        )
                    }

                    // if (name === 'CtaBoxes') {
                    //     return (
                    //         <CtaBoxes data={block} slug={state?.slug} location={location} key={`page-block-${name}`} />
                    //     )
                    // }
                }

                return true
            })}
            {wpSettings &&
                <CtaBoxes data={wpSettings.ctaBoxes} slug={state?.slug} location={location} key={`page-block-cta-boxes`} />
            }
        </Layout>
      </Fragment>
  )
}

export const query = graphql`
  query($id: String!) {
    wpState(id: { eq: $id }) {
      seo {
       title
       metaDesc
       focuskw
       metaKeywords
       opengraphTitle
       opengraphDescription
       opengraphImage {
         altText
         sourceUrl
         srcSet
       }
       twitterTitle
       twitterDescription
       twitterImage {
         altText
         sourceUrl
         srcSet
       }
       canonical
       cornerstone
       schema {
         articleType
         pageType
         raw
       }
      }
      slug
      title
      content
      blocks {
        layouts {
          ... on WpState_Blocks_Layouts_TextContentWithLogo {
            content
            fieldGroupName
            backgroundLogoImage {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            logo {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            subtitle
            title
          }
          ... on WpState_Blocks_Layouts_ContentRightWithToggle {
            fieldGroupName
            contentAboveToggle
            contentBelowToggle
            contentBelowBox {
              button {
                target
                title
                url
              }
              content
              subtitle
              title
            }
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            linksBelowToggle {
              link {
                target
                title
                url
              }
            }
            toggle {
              content
              subtitle
              title
            }
          }
        }
      }
    }
    wp {
      themeGeneralSettings {
        themeSettings {
          ctaBoxes {
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            button {
              target
              title
              url
            }
          }
        }
      }
    }
  }
`
